// Layout.js
import React, { useEffect, useState, createContext, useContext } from "react";
import Header from './components/header';
import { Outlet } from 'react-router-dom';
// import { fetchOrgID, fetchIsOverviewAccount, fetchOrganizationIDs, fetchSpecificTeamOrganizationIDs, } from "./functions/organizations";
// import { fetchProductTypes } from "./functions/outlets";
import { auth } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { fetchUserRole } from "./functions/users";
import { fetchAndSetOrgID, fetchDashboardInfo } from "./functions/organizations";
import { fetchOutlets } from "./functions/outlets";
import { fetchOrgEvents, fetchChildOrgEvents } from "./functions/events";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

const Layout = () => {
  const navigate = useNavigate();
  const [user, waiting, error] = useAuthState(auth);
  const [roleLoading, setRoleLoading] = useState(true);
  const [role, setRole] = useState("");
  const [allProdTypes, setAllProdTypes] = useState(
    localStorage.getItem("productTypes") || []
  );
  const [orgID, setOrgID] = useState(localStorage.getItem("orgID") || "");
  const [isOverviewAccount, setIsOverviewAccount] = useState(
    localStorage.getItem("isOverviewAccount") || false
  );
  const [allOrgs, setAllOrgs] = useState();
  const [timezone, setTimezone] = useState(localStorage.getItem("timezone") || "UTC");
  const [rate, setRate] = useState(localStorage.getItem("rate") || 0.16);
  const [orgName, setOrgName] = useState(localStorage.getItem("orgName") || "");
  const [child_orgs, setChild_Orgs] = useState([]);
  const [selectedOrgID, setSelectedOrgID] = useState(
    localStorage.getItem("selectedOrgID") || ""
  );
  const [orgData, setOrgData] = useState([]);
  const [wattHours, setWattHours] = useState([]);
  const [wattsLoading, setWattsLoading] = useState(true);
  const [savings, setSavings] = useState([]);
  const [savingsLoading, setSavingsLoading] = useState(true);
  const [tasks, setTasks] = useState([]);
  const [tasksLoading, setTasksLoading] = useState(true);
  const [schedules, setSchedules] = useState([]);
  const [scheduleLoading, setScheduleLoading] = useState(true);
  const [averageUsage, setAverageUsage] = useState([]);
  const [averageLoading, setAverageLoading] = useState(true);
  const [update, setUpdate] = useState(false);
  const [outlets, setOutlets] = useState([]);
  const [modalEdit, setModalEdit] = useState(false);
  const [outletsLoading, setOutletsLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date()); // Store the date selected by TimePicker
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [events, setEvents] = useState([]);
  const [refresh, setRefresh] = useState(true);

  const [layoutProps, setLayoutProps] = useState({
    orgID: localStorage.getItem("orgID") || "",
    orgName: localStorage.getItem("orgName") || "",
    timezone: localStorage.getItem("timezone") || "UTC",
    setTimezone: setTimezone,
    rate: localStorage.getItem("rate") || 0.16,
    setRate: setRate,
    selectedOrgID: localStorage.getItem("selectedOrgID") || "",
    setSelectedOrgID: setSelectedOrgID,
    allOrgs: localStorage.getItem("allOrgs") || [],
    child_orgs: localStorage.getItem("child_orgs") || [],
    setChild_Orgs: setChild_Orgs,
    isOverviewAccount: localStorage.getItem("isOverviewAccount") || false,
    allProdTypes: localStorage.getItem("productTypes") || [],
    orgData: [],
    schedules: [], scheduleLoading: true,
    savings: [], savingsLoading: true,
    wattHours: [], wattsLoading: true,
    tasks: [], tasksLoading: true,
    averageUsage: [], averageLoading: true,
    outlets: [], outletsLoading: true,
    modalEdit: false, setModalEdit: setModalEdit,
    windowDimensions: windowDimensions,
    selectedDate: selectedDate, setSelectedDate: setSelectedDate,
    events: [],
    refresh: refresh, setRefresh: setRefresh,
  });
  // window dimensions
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  // gets usesrRole
  useEffect(() => {
    const getRole = async () => {
      const userRole = await fetchUserRole(user.uid);
      setRoleLoading(false);
      setRole(userRole);
      if (userRole == "pending") {
        navigate("/pending-role");
      };
    };
    if (user) {
      getRole();
    }
    // removed for now, was causing loading problems
    //  else {
    //   navigate("/login");
    // };
  }, [user, waiting]);
  // fetch orgID
  useEffect(() => {
    // Function to fetch data from Firestore
    fetchAndSetOrgID(
      orgID,
      setAllProdTypes,
      setOrgID,
      setAllOrgs,
      setTimezone,
      setRate,
      setOrgName,
      setChild_Orgs,
      setLayoutProps,
    );
  }, [orgID]);
  // gets organization data, and data of child orgs
  // does not update on the update interval to minimize data calls related to these datasets
  useEffect(() => {
    fetchDashboardInfo(
      selectedOrgID, orgID,
      setOrgData,
      setChild_Orgs,
      setSchedules, setScheduleLoading,
      setWattHours, setWattsLoading,
      setSavings, setSavingsLoading,
      setTasks, setTasksLoading,
      setAverageUsage, setAverageLoading,
      setLayoutProps,
    );
  }, [orgID, selectedOrgID,]);
  // refresh interval
  useEffect(() => {
    const interval = setInterval(() => {
      setUpdate(!update);
    }, 120000); // 120000ms = 120 seconds
    return () => {
      clearInterval(interval);
    };
  }, [update]);
  // gets outlets
  useEffect(() => {
    setOutletsLoading(true);

    if (selectedOrgID !== "" && child_orgs.length == 0) {//fetch outlets for specific org
      fetchOutlets([selectedOrgID], timezone, setOutlets, setOutletsLoading,);
    } else if (child_orgs.length > 0) {//fetch outlets for child orgs
      // ensures child_orgs is in the right format for the query
      if (Array.isArray(child_orgs)) {
        fetchOutlets(child_orgs, timezone, setOutlets, setOutletsLoading,);
      } else {
        fetchOutlets(child_orgs.split(","), timezone, setOutlets, setOutletsLoading,);
      };
    } else { //fetch outlets for logged in org
      fetchOutlets([orgID], timezone, setOutlets, setOutletsLoading,);
    };
  }, [update, modalEdit, selectedOrgID, orgID, child_orgs]);
  // Fetch events
  useEffect(() => {
    const fetchEvents = async () => {
      if (selectedOrgID !== "" && child_orgs?.length === 0) {
        await fetchOrgEvents(selectedOrgID, setEvents);
      } else if (child_orgs.length !== 0) {
        await fetchChildOrgEvents(orgID, setEvents, child_orgs);
      } else {
        await fetchOrgEvents(orgID, setEvents);
      };
    };

    if (orgID) {
      fetchEvents();
    };
  }, [orgID, selectedOrgID, child_orgs, refresh]);
  // update app state
  useEffect(() => {
    setLayoutProps(prevState => ({
      ...prevState,        // Spread the previous state
      selectedOrgID: selectedOrgID,
      timezone: timezone,
      rate: rate,
      child_orgs: child_orgs,
      role: role,
      schedules: schedules, scheduleLoading: scheduleLoading, setSchedules: setSchedules,
      savings: savings, savingsLoading: savingsLoading,
      wattHours: wattHours, wattsLoading: wattsLoading,
      tasks: tasks, tasksLoading: tasksLoading, setTasksLoading: setTasksLoading,
      averageUsage: averageUsage, averageLoading: averageLoading,
      outlets: outlets, setOutlets: setOutlets,
      outletsLoading: outletsLoading, setOutletsLoading: setOutletsLoading,
      modalEdit: modalEdit,
      orgData: orgData,
      update: update,
    }));
  }, [outlets, update, modalEdit, selectedOrgID, schedules, savings, wattHours, tasks, averageUsage]);

  return (
    <div>
      <Header
        orgID={orgID}
        selectedOrgID={selectedOrgID}
        setSelectedOrgID={setSelectedOrgID}
        setTimezone={setTimezone}
        setRate={setRate}
        width={windowDimensions.width}
        isOverviewAccount={isOverviewAccount}
      />
      <main style={{
        marginTop: '86px',
      }}>
        <Outlet context={layoutProps} />
      </main>
    </div>
  );
};

export default Layout;
