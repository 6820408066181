import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import SavingsDisplay from "../../components/savingsDisplay";
import DailyUsageChart from "../../components/charts/dailyUsageChart";
import ApplianceDetailsCard from "../../components/applianceDetailsCard";
import CalendarContainer from "../../components/calendarContainer";
import { fetchOrgEvents } from "../../functions/events";
import { white } from "../../lib/colors";
import { fetchOrgSchedules } from "../../functions/schedules";
import IconButton from "../../components/iconButton";

export default function ApplianceDetailsScreen() {
  const location = useLocation();
  const navigate = useNavigate();
  const outlet = location.state?.outlet;
  const from = location.state?.path;
  const [events, setEvents] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [scheduleLoading, setScheduleLoading] = useState(false);

  const layoutProps = useOutletContext();

  // Fetch events for the specific outlet
  useEffect(() => {
    if (layoutProps.orgData[0]?.uid && outlet?.id) {
      fetchOrgEvents(layoutProps.orgData[0].uid, setEvents, outlet.id);
    }
  }, [outlet]);

  useEffect(() => {
    if (layoutProps.orgData[0]?.uid && outlet?.id) {
      const fetchSchedules = async () => {
        setScheduleLoading(true);
        await fetchOrgSchedules(
          [layoutProps.orgData[0].uid],
          setSchedules,
          setScheduleLoading,
          outlet.id
        );
      };

      fetchSchedules();
    }
  }, [layoutProps.orgData, outlet]);

  const goBack = () => {
    if (
      from !== "/appliances/details/edit" &&
      from !== undefined
    ) {
      navigate(from, {
        state: {
          updateTab: true
        }
      });
    } else if (from === "/appliances/details/edit") {
      navigate('/appliances', {
        state: {
          updateTab: true
        }
      });
    } else {
      navigate("/", {
        state: {
          updateTab: true
        }
      });
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#1F1F1F",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "20px",
        gap: "20px",
      }}
    >
      {/* Header Section */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          maxWidth: "1200px",
        }}
      >
        <IconButton
          iconName="arrow-left"
          size={35}
          onClick={goBack} // Navigate back
        />
        <Typography
          sx={{
            color: white,
            fontFamily: "IBM Plex Sans",
            fontSize: "32px",
            fontWeight: 400,
            lineHeight: "40px",
          }}
        >
          {outlet.name} Details
        </Typography>
      </div>
      {/* </div> */}
      <div
        style={{
          display: "flex",
          gap: "40px",
          padding: "40px",
          borderRadius: "24px",
          maxWidth: "1200px",
          width: "100%",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          backgroundColor: white,
        }}
      >
        {/* Left Section: ApplianceDetailsCard */}
        <ApplianceDetailsCard
          outlet={outlet}
          prodTypes={layoutProps.prodTypes}
          orgData={layoutProps.orgData}
          wattHours={layoutProps.wattHours}
          averageUsage={layoutProps.averageUsage}
          savings={layoutProps.savings}
        />

        {/* Right Section */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "520px",
            height: "500px",
            padding: "20px",
            borderRadius: "16px",
            border: "1px solid #E0E0E0",
            backgroundColor: "#F9F9F9",
          }}
        >

          {/* Daily Usage Chart */}
          <Box
            sx={{
              marginBottom: "20px",
              // padding: "16px",
              backgroundColor: "#FFFFFF",
              borderRadius: "16px",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              //height:"70%"
            }}
          >
            <DailyUsageChart
              layoutProps={layoutProps}
              outletID={outlet.id}
            />
          </Box>

          {/* Savings Display */}
          <Box
            sx={{
              padding: "16px",
              backgroundColor: "#FFFFFF",
              borderRadius: "16px",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <SavingsDisplay
              layoutProps={layoutProps}
              outletID={outlet.id}
            />
          </Box>
        </Box>
      </div>

      {/* Schedule Section */}
      <Box
        sx={{
          width: "100%",
          maxWidth: "1200px",
          padding: "40px",
          borderRadius: "16px",
          border: "1px solid #E0E0E0",
          backgroundColor: "#F9F9F9",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          marginBottom: '50px'
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            marginBottom: "20px",
            fontSize: "18px",
            textAlign: "center",
          }}
        >
          Schedules and Events
        </Typography>

        <CalendarContainer
          layoutProps={layoutProps}
          filteredEvents={events}
          filteredSchedules={schedules}
        />
      </Box>
    </div>
  );
};
