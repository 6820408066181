import React, { useState, useEffect } from "react";
import {
  black,
  primary500,
  grey600,
} from "../../lib/rebrandColors/colors";
import { useLocation, useNavigate } from "react-router-dom";
import "../../App.css"; // Import your fonts
import TextButton from "../textButton";
import { CircularProgress, } from "@mui/material";
import WifiIcon from "@mui/icons-material/Wifi";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import WifiLockIcon from "@mui/icons-material/WifiLock";
import Chip from "../chip";
import ApplianceTypeList from "../applianceTypeList";
import { adjustOutlets } from "../../functions/outlets";
import CustomIcon from "../icon";

export default function OutletList({
  layoutProps,
  width,
  height
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [filter, setFilter] = useState(true);
  const [filterLoading, setFilterLoading] = useState(true);
  const [filteredOutlets, setFilteredOutlets] = useState(layoutProps.outlets);
  const [currentOutlet, setOutlet] = useState({});
  const [index, setIndex] = useState();
  const path = location.pathname;
  const [selectedTab, setSelectedTab] = useState(location.state?.updateTab ? 1 : 0);

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const handleRowClick = (outlet, i) => {
    setIndex(i);
    setOutlet(outlet);
    navigate("/appliances/details", {
      state: {
        outlet,
        path,
      },
    });
  };

  useEffect(() => {
    adjustOutlets(
      layoutProps.timezone,
      layoutProps.wattHours,
      layoutProps.savings,
      layoutProps.outlets,
      layoutProps.orgData,
      setFilteredOutlets,
      filter,
      setFilterLoading,
    );
  }, [layoutProps.outletsLoading, layoutProps.orgData, layoutProps.timezone, layoutProps.outlets]);

  return (
    <div style={{
      margin: ".5%",
      minWidth: "500px",
      borderRadius: "24px",
      minHeight: "824px",
    }}>
      {location.pathname === '/' &&
        <div style={{
          display: 'flex',
          alignItems: 'center',
          marginLeft: '20px',
          marginTop: '30px',
        }}>
          <CustomIcon
            name="plug"
            color={black}
            size={30}
          />
          <div
            className="bodyLarge"
            style={{
              marginLeft: '8px',
            }}
          >
            Appliances
          </div>
        </div>
      }
      <div style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        marginTop: '20px',
        marginBottom: '20px',
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          width: width ? width : "480px",
        }}>
          <TextButton
            label='Appliance type'
            underlined={true}
            onPress={() => handleTabChange(0)}
            fill={selectedTab == 0 && primary500}
          />
          <div style={{ marginLeft: '10px' }}>
            <TextButton
              label='Individual appliances'
              underlined={true}
              onPress={() => handleTabChange(1)}
              fill={selectedTab == 1 && primary500}
            />
          </div>
        </div>
      </div>
      <div style={{
        display: "flex",
        flexDirection: "column",
        height: height,
        overflowY: "scroll",
        alignItems: "center",
        width: "100%",
      }}>
        {filterLoading ? (
          <div style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "50px",
          }}>
            <CircularProgress />
          </div>
        ) : selectedTab === 1 ? (
          // NOTE: Switch this to flatList
          filteredOutlets.length > 0 ? (
            filteredOutlets.map((outlet, i) => {
              return (
                <div
                  style={{
                    display: "flex",
                    width: width ? width : "480px",
                    minHeight: "88px",
                    backgroundColor: "#EEEEEE",
                    borderRadius: "16px",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: "5px",
                    marginTop: i == 0 ? "2px" : "16px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleRowClick(outlet, i)}
                  key={outlet.id}
                >
                  <div style={{
                    width: "90%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        className="labelMedium"
                        style={{
                          fontSize: "14px",
                          fontWeight: 600,
                          color: grey600,
                        }}
                      >
                        {outlet.name}
                      </div>
                      <div
                        className="labelMedium"
                        style={{
                          fontSize: "12px",
                          color: "#666666",
                        }}
                      >
                        {outlet.orgName}
                      </div>
                      <div
                        className="labelMedium"
                        style={{
                          fontSize: "12px",
                          color: "#666666",
                        }}
                      >
                        Today: {outlet.dailyUsage.toFixed(2)} kWh
                      </div>
                      <div
                        className="labelMedium"
                        style={{
                          color: outlet.status == "Off" ? primary500 : "green",
                          fontSize: "12px",
                        }}
                      >
                        {outlet.status}
                      </div>
                    </div>
                    {location.pathname === "/appliances" && (
                      <div>
                        <Chip
                          label={
                            outlet.productType == ""
                              ? "N/A"
                              : outlet.productType
                          }
                          alert={false}
                          iconName="plug"
                        />
                      </div>
                    )}
                    {location.pathname === "/appliances" &&
                      (outlet.wifiStatus === "Connected" ? (
                        <div>
                          <Chip
                            label={outlet.wifiStatus}
                            alert={false}
                            icon={<WifiIcon style={{ color: "#009C49" }} />}
                          />
                        </div>
                      ) : outlet.wifiStatus === "Disconnected" ? (
                        <Chip
                          label={outlet.wifiStatus}
                          alert={true}
                          icon={<WifiOffIcon style={{ color: "#FF6D2A" }} />}
                        />
                      ) : (
                        <Chip
                          label={outlet.wifiStatus}
                          alert={true}
                          icon={
                            <WifiLockIcon
                              style={{ marginLeft: "3%", color: "orange" }}
                            />
                          }
                        />
                      ))}
                    <div style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}>
                      <div
                        className="buttonSmall"
                        style={{
                          fontSize: "10px",
                          lineHeight: "10px",
                          textAlign: "center",
                        }}
                      >
                        Estimated
                        <br />
                        savings:
                      </div>
                      <div style={{
                        width: "63px",
                        height: "32px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "24px",
                        backgroundColor: "#FFFFFF",
                        marginTop: "5px",
                      }}>
                        <div
                          className="buttonSmall"
                          style={{
                            color: "#E50914",
                            fontSize: "12px",
                            fontWeight: "700",
                          }}
                        >
                          ${outlet.costSavings.toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div
              className="headlineMedium"
              style={{
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                marginTop: "50px",
                color: "#999999",
              }}
            >
              No appliances available
            </div>
          )
        ) : (
          <ApplianceTypeList layoutProps={layoutProps} height={height} width={width} />
        )}
      </div>
    </div>
  );
}