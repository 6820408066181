import React, { useState, useEffect } from "react";
import "../../App.css"; // Import your fonts
import DailyScheduleView from "../../components/dailyScheduleView";
import TaskList from "../../components/tasksList";
import OutletList from "../../components/outletList";
import OrganizationPicker from "../../components/orgPicker";
import CustomLogo from "../../components/logo";
import { useOutletContext } from "react-router-dom";
import DailyUsageChart from "../../components/charts/dailyUsageChart";
import SavingsDisplay from "../../components/savingsDisplay";
import BuildingsContainer from "../../components/buildingsContainer"; //not currently implemented


export default function Dashboard() {
  // variables from layout
  const layoutProps = useOutletContext();

  return (
    <>
      <div style={{
        display: "flex",
        backgroundColor: "#1F1F1F",
        height: "auto",
        minHeight: "100vh",
        minWidth: "1300px",
        justifyContent: "center",
        marginTop: "86px",
      }}>
        <div style={{
          marginTop: "30px",
          display: "flex",
          flexDirection: "column",
        }}>
          <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}>
            <div
              className="headlineLarge"
              style={{
                width: "350px",
                height: "auto",
                display: "block",
                alignSelf: "start",
                marginTop: "10px",
                color: "#FFFFFF",
              }}
            >
              Dashboard
            </div>
            {(layoutProps.isOverviewAccount === "true" ||
              layoutProps.orgID === "LZqI3R6MInUuwtgtROPK") && (
                <OrganizationPicker
                  layoutProps={layoutProps}
                  label={true}
                />
              )}
          </div>
          <div style={{
            marginTop: "30px",
            display: "flex",
            flexDirection: "row",
            marginBottom: "30px",
          }}>
            <div style={{
              display: "flex",
              flexDirection: "column",
            }}>
              <div style={{
                width: "560px",
                display: "flex",
                borderRadius: "24px",
                minHeight: "693px",
                backgroundColor: "#FAFAFA",
                maxHeight: "693px",
                overflowY: "hidden",
                marginRight: "16px",
                justifyContent: "center",
                marginBottom: "15px",
              }}>
                <div style={{ width: "95%" }}>
                  {/* schedule component */}
                  <DailyScheduleView
                    layoutProps={layoutProps}
                    width={layoutProps.windowDimensions.width}
                    height={"615px"}
                  />
                </div>
              </div>
              <div style={{
                width: "560px",
                borderRadius: "24px",
                minHeight: "339px",
                backgroundColor: "#FAFAFA",
                maxHeight: "339px",
                overflowY: "hidden",
                marginBottom: "15px",
              }}>
                <TaskList
                  layoutProps={layoutProps}
                  height={"255px"}
                />
              </div>
            </div>
            <div style={{
              display: "flex",
              flexDirection: "column",
            }}>
              <div style={{
                width: "560px",
                borderRadius: "24px",
                minHeight: "500px",
                backgroundColor: "#FAFAFA",
                maxHeight: "620px",
                overflowY: "hidden",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: "15px",
              }}>
                <DailyUsageChart
                  layoutProps={layoutProps}
                />
                <div style={{ marginTop: '50px', marginBottom: '20px', width: '100%' }}>
                  <SavingsDisplay
                    layoutProps={layoutProps}
                  />
                </div>
              </div>
              <div style={{
                width: "560px",
                borderRadius: "24px",
                minHeight: "400px",
                backgroundColor: "#FAFAFA",
                maxHeight: "409px",
                overflowY: "hidden",
                marginBottom: "15px",
              }}>
                <OutletList
                  layoutProps={layoutProps}
                  height={"255px"}
                />
              </div>
              {/*UNCOMMENT THE BELOW WHEN READY FOR BUILDINGS CONTAINER*/}
              {/* {(isOverviewAccount === "true" ||
                orgID === "LZqI3R6MInUuwtgtROPK") && (
                <div
                  style={{
                    width: "560px",
                    borderRadius: "24px",
                    minHeight: "339px",
                    backgroundColor: "#FAFAFA",
                    // maxHeight: "349px",
                    overflowY: "hidden",
                    // scrollbarWidth:"none"
                  }}
                >
                  <BuildingsContainer orgID={orgID} selectedOrgID={selectedOrgID} />
                </div>
              )} */}
            </div>
          </div>
          <div style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}>
            <CustomLogo name="horizontal-white" />
          </div>
        </div>
      </div>
    </>
  );
};
